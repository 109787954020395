<template>
  <div class="bbr-videos--search-video-input">
    <v-combobox
      v-model="form.video"
      :label="label"
      :readonly="false"
      :items="videos"
      :error-messages="errors"
      :hide-details="!hasErrors"
      :search-input.sync="search"
      :return-object="returnObject"
      :hide-no-data="hideNoData"
      :clearable="clearable && search && !form.$busy"
      @change="onVideoSelect"
      item-text="title"
      item-value="id"
      no-filter
      outlined
      flat
    >
      <template v-if="form.$busy" v-slot:append>
        <v-fade-transition leave-absolute>
          <v-progress-circular size="24" color="primary" indeterminate />
        </v-fade-transition>
      </template>

      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.title }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="withTags">
            Tags: {{ data.item.tags | readable }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="withDescription">
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import Video from '@/models/Video'
import Form from '@/utils/form'

import { isEmpty, debounce } from 'lodash'

export default {
  name: 'SearchVideosInput',

  props: {
    value: {
      type: [Number, Object],
    },

    label: {
      type: String,
      default: 'Search Video',
    },

    completed: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: [Object, Array],
      default: () => {
        return []
      },
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    videoOnly: {
      type: Boolean,
      default: false,
    },

    extraOnly: {
      type: Boolean,
      default: false,
    },

    returnObject: {},

    withTags: {
      type: Boolean,
      default: false,
    },

    withDescription: {
      type: Boolean,
      default: false,
    },

    trainerId: {
      required: false,
    },
  },

  data() {
    return {
      search: '',
      videos: [],
      form: new Form({
        video: null,
      }),
    }
  },

  computed: {
    hasErrors() {
      return !!this.errors.length
    },

    hasSearch() {
      return !isEmpty(this.search)
    },

    hideNoData() {
      return !this.hasSearch && !!this.videos.length
    },

    hasTrainerId() {
      return isEmpty(this.trainerId)
    },
  },

  methods: {
    searchVideo: debounce(function() {
      this.videos = []

      this.fetchVideos().then(({ data }) => {
        this.form.$busy = false
        this.videos = data
      })
    }, 250),

    async fetchVideos() {
      let video = await Video.where('search', this.search)

      return video
        .include(['tags'])
        .orderBy('title')
        .params({ limit: 200 })
        .page(1)
        .get()
    },

    onVideoSelect(video = {}) {
      if (video) {
        this.$emit('input', video.id)
      }

      this.$emit('select', video)
    },
  },

  watch: {
    search(val) {
      if (val) {
        this.loading = true
        this.form.$busy = true

        this.searchVideo()
      }
    },

    value(value) {
      if (!value) {
        this.search = ''
        this.form.video = null
      }
    },
  },
}
</script>
