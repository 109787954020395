import Event from '@/services/eventBus'
import Program from '@/models/Program'

import { mdiCheck, mdiTrashCanOutline, mdiPlus, mdiArrowRight } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { upperFirst, map } from 'lodash'
import Challenge from '@/models/Challenge'

export default {
  data() {
    return {
      loading: false,
      icons: {
        add: mdiPlus,
        check: mdiCheck,
        next: mdiArrowRight,
        delete: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    ...mapState({
      challenge: (state) => state.challenges.selectedChallenge,
    }),

    hasDeload() {
      return this.challenge.has_deload
    },

    isCompleted() {
      return this.challenge.completed_at
    },
  },

  methods: {
    ...mapActions({
      saveChallenge: 'challenges/saveChallenge',
    }),

    async saveAndComplete() {
      if (this.loading) return

      this.loading = true

      let { data } = await Program.include(['trainers']).find(this.challenge.id)
      data.training_program_ids = map(data.training_programs, 'id')
      data.trainer_ids = map(data.trainers, 'id')

      data.completed = 1

      this.saveChallenge(data)
        .then((res) => {
          Event.$emit('udpate-draft-challenge-list')
          Event.$emit('udpate-current-challenge-list')

          this.timeOut(() => {
            this.loading = false
            this.$router.push({ name: 'current.challenges' })
          })
        })
        .catch(({ response }) => {
          this.timeOut(() => {
            this.loading = false
            if (response && response.status === 422) {
              this.$refs.errorToast.open({
                color: 'red',
                message: upperFirst(response.data.message),
              })
            }
          })
        })
    },

    async save() {
      this.loading = true

      if (this.$attrs.id) {
        let { data } = await Challenge.include(['trainers']).find(
          this.$attrs.id
        )
        data.training_program_ids = map(data.training_programs, 'id')
        data.trainer_ids = map(data.trainers, 'id')
        data.rich_description = this.challenge.rich_description

        this.saveChallenge(data)
          .then(({ data }) => {
            this.timeOut(() => {
              this.loading = false
            })
          })
          .catch(({ response }) => {
            this.timeOut(() => {
              this.loading = false
              if (response && response.status === 422) {
                this.$refs.errorToast.open({
                  color: 'red',
                  message: upperFirst(response.data.message),
                })
              }
            })
          })
        return data
      }
    },
    timeOut(callback, time = 500) {
      setTimeout(() => {
        callback()
      }, time)
    },
  },
}
