<template>
  <div class="challenge-videos mb-12">
    <v-row>
      <v-col cols="12" sm="10">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Challenge Videos</h2>
          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            @click="saveForLater"
            :loading="loading"
            depressed
            text
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            v-if="hasDeload"
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="loading"
            depressed
            text
          >
            <span> Next </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>

          <v-btn
            v-else
            class="bg-primary-gradient primary next-button px-8"
            @click="saveAndComplete"
            :loading="loading"
            :disabled="false"
            :text="true"
            depressed
          >
            <span v-if="!isCompleted"> Complete Challenge </span>
            <span v-else> Save Changes </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="12" sm="10">
        <challenge-video-form />
        <challenge-videos class="mt-12" />
      </v-col>
    </v-row>

    <v-toast ref="errorToast" />
    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ChallengeVideoForm from '@/components/forms/challenges/ChallengeVideoForm'
import ChallengeVideos from '@/components/forms/challenges/ChallengeVideos'
import ChallengeWorkoutsMixin from '../mixins/ChallengeWorkoutsMixin'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import VToast from '@/components/elements/Toast'

import { mdiCheck, mdiTrashCanOutline, mdiPlus, mdiArrowRight } from '@mdi/js'
import { mapGetters } from 'vuex'
import { map } from 'lodash'

export default {
  name: 'NewChallengeVideos',

  mixins: [ChallengeWorkoutsMixin],

  components: {
    VToast,
    ConfirmDialog,
    ChallengeVideos,
    ChallengeVideoForm,
  },

  data() {
    return {
      icons: {
        add: mdiPlus,
        check: mdiCheck,
        next: mdiArrowRight,
        delete: mdiTrashCanOutline,
      },
      loading: false,
      venue: 0,
    }
  },

  computed: {
    ...mapGetters({
      isCompleted: 'challenges/isSelectedChallengeComplete',
    }),

    videosId() {
      return map(this.challenge.videos, 'id')
    },

    hasVideos() {
      return !!this.challenge.videos.length
    },
  },

  methods: {
    saveForLater() {
      this.loading = true

      this.updateDraftList(this.challenge)

      this.timeOut(() => {
        this.loading = false
        this.$router.push({ name: 'draft.challenges' })
      })
    },

    async saveAndNext() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'challenge.deload.workouts',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'new.challenge.deload.workouts',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },

    timeOut(callback, time = 500) {
      setTimeout(() => {
        callback()
      }, time)
    },
  },
}
</script>

<style lang="scss" scoped>
.no-videos {
  margin-top: -80px;
}
</style>
