<template>
  <div class="bbr-challenges--video-form">
    <v-row>
      <v-col cols="12" sm="3">
        <search-video
          v-model="form.video_id"
          :trainer-id="form.trainer_id"
          :errors="form.$getError('video_id')"
          @select="addVideo"
          with-description
          return-object
          video-only
          clearable
          with-tags
          completed
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="1">
        <v-select
          v-model="form.week"
          :items="weeks"
          :error-messages="form.$getError('week')"
          label="Week"
          item-value="id"
          item-text="name"
          :hide-details="!form.$getError('week')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="2">
        <v-select
          v-model="form.day"
          :items="days"
          :error-messages="form.$getError('day')"
          label="Day"
          item-value="id"
          item-text="name"
          :hide-details="!form.$getError('day')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-btn color="primary" @click="addVideoToChallenge" block x-large>
          <v-icon class="mr-3"> {{ icons.add }} </v-icon>
          Add Video
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchVideo from '@/components/elements/videos/SearchVideosInput'
import Video from '@/models/Video'
import Form from '@/utils/form'

import { mapState, mapMutations } from 'vuex'
import { map, values } from 'lodash'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ChallengeVideoForm',

  components: {
    SearchVideo,
  },

  data() {
    return {
      challenge: {},
      video: {},

      days: [
        { id: 0, name: 'SUNDAY' },
        { id: 1, name: 'MONDAY' },
        { id: 2, name: 'TUESDAY' },
        { id: 3, name: 'WEDNESDAY' },
        { id: 4, name: 'THURSDAY' },
        { id: 5, name: 'FRIDAY' },
        { id: 6, name: 'SATURDAY' },
      ],

      icons: {
        add: mdiPlus,
      },

      form: new Form({
        day: null,
        week: null,
        video_id: null,
      }),
    }
  },

  created() {
    this.challenge = this.selectedChallenge
  },

  computed: {
    ...mapState({
      selectedChallenge: (state) => state.challenges.selectedChallenge,
    }),

    weeks() {
      return map([...Array(this.challenge.week_count).keys()], (week) => {
        let _week = week + 1

        return {
          id: _week,
          name: _week,
        }
      })
    },
  },

  methods: {
    ...mapMutations({
      setChallengeVideo: 'challenges/setSelectedChallengeVideo',
    }),

    addVideo(video) {
      this.video = video || {}
    },

    async addVideoToChallenge() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        let video = new Video(this.form).for(this.selectedChallenge)
        let data = await video.save()

        this.setChallengeVideo(values(data))

        this.form.$reset()
      } catch ({ response }) {
        this.form.$timeout(() => {
          this.form.$busy = false

          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
        })
      }
    },
  },

  watch: {
    selectedChallenge(challenge) {
      this.challenge = challenge
      if (challenge.trainer) {
        this.form.trainer_id = challenge.trainer.id
      }
    },

    errors(errors) {
      this.form.$setErrors(errors)
    },
  },
}
</script>
