<template>
  <v-snackbar
    v-model="toast"
    :color="options.color"
    :timeout="options.timeout"
    bottom
    right
  >
    {{ options.message }}

    <template v-if="!custom" v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" class="px-5" @click="close" dark text>
        Close
      </v-btn>
    </template>

    <slot name="close"></slot>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Toast',

  props: {
    persistent: {
      type: Boolean,
      default: false,
    },

    custom: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      toast: false,
      options: {
        color: null,
        message: null,
        timeout: 3000,
      },
    }
  },

  methods: {
    open(options = null) {
      this.toast = true
      this.options = { ...this.options, ...options }
    },

    close() {
      this.toast = false

      this.$emit('close')
    },
  },
}
</script>
